// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArTextarea-module--ar-h1-mobile--2JiNF";
export var arH2Mobile = "ArTextarea-module--ar-h2-mobile--QMsA1";
export var arH3Mobile = "ArTextarea-module--ar-h3-mobile--3qlSf";
export var arH4Mobile = "ArTextarea-module--ar-h4-mobile--2XnGB";
export var arH5Mobile = "ArTextarea-module--ar-h5-mobile--i6zAg";
export var arH1 = "ArTextarea-module--ar-h1--18c2n";
export var arH2 = "ArTextarea-module--ar-h2--2qDO2";
export var arH3 = "ArTextarea-module--ar-h3--2kpmE";
export var arH4 = "ArTextarea-module--ar-h4--L1YDX";
export var arH5 = "ArTextarea-module--ar-h5--MgKAf";
export var arParagraphXl = "ArTextarea-module--ar-paragraph-xl--38Hap";
export var arParagraphXlMedium = "ArTextarea-module--ar-paragraph-xl-medium--1HZuh";
export var arParagraphBase = "ArTextarea-module--ar-paragraph-base--37gox";
export var arParagraphBaseMedium = "ArTextarea-module--ar-paragraph-base-medium--3puHi";
export var arParagraphSmall = "ArTextarea-module--ar-paragraph-small--2ALo3";
export var arParagraphSmallMedium = "ArTextarea-module--ar-paragraph-small-medium--p640q";
export var arParagraphXsmall = "ArTextarea-module--ar-paragraph-xsmall--1Wzgj";
export var arParagraphXsmallMedium = "ArTextarea-module--ar-paragraph-xsmall-medium--29hz-";
export var arLinkMobile = "ArTextarea-module--ar-link-mobile--1RTFy";
export var arLinkDesktop = "ArTextarea-module--ar-link-desktop--3D57b";
export var arQuote = "ArTextarea-module--ar-quote--mJGlz";
export var arQuoteMobile = "ArTextarea-module--ar-quote-mobile--318qb";
export var arGradientLight = "ArTextarea-module--ar-gradient-light--2eJ7Q";
export var arGradientBlue = "ArTextarea-module--ar-gradient-blue--2_lNF";
export var arTransition = "ArTextarea-module--ar-transition--1ng8y";
export var container = "ArTextarea-module--container--15bzi";
export var error = "ArTextarea-module--error--_GA7p";
export var inputWrapper = "ArTextarea-module--inputWrapper--uTDkh";
export var hasValue = "ArTextarea-module--hasValue--2_okm";